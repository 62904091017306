import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TabelRekap from "../../components/table/TabelRekap";

function EditHitungBerat() {
  const { uuid } = useParams();

  const [namaMitra, setNamaMitra] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/mitra/${uuid}`)
      .then((res) => res.json())
      .then((data) => {
        setNamaMitra(data.nama_mitra);
      })
      .catch((err) => console.log(err));
  }, [uuid]);

  return (
    <div className='w-full'>
      <div className='p-4 flex sm:flex-row flex-col justify-between'>
        <h1 className='md:text-4xl text-2xl font-bold'>Hitung Berat</h1>
        <div className='flex flex-row gap-4 justify-between sm:mt-0 mt-4'>
          <Link
            to={`/form-hitung-berat/${namaMitra}`}
            className='bg-blue-500 text-white font-semibold sm:text-base text-xs px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded inline'>
            Hitung berat
          </Link>
          <Link
            to='/'
            className='bg-red-500 text-white font-semibold sm:text-base text-xs px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded inline'>
            Kembali
          </Link>
        </div>
      </div>
      <div className='p-5 my-3 w-full'>
        <TabelRekap uuid={uuid} />
      </div>
    </div>
  );
}

export default EditHitungBerat;
