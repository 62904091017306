import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TabelDetail from "../../components/table/TabelDetail";
import TabelRekapDetail from "../../components/table/TabelRekapDetail";
import CetakDatilHitung from "../../components/print/CetakDetailHitung";

function DetailHitungBerat() {
  const { uuid } = useParams();

  const [namaMitra, setNamaMitra] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/mitra/${uuid}`)
      .then((res) => res.json())
      .then((data) => {
        setNamaMitra(data.nama_mitra);
      })
      .catch((err) => console.log(err));
  }, [uuid]);

  return (
    <div className='w-full'>
      <div className='p-4 flex flex-row justify-between items-center'>
        <h1 className='md:text-4xl text-2xl font-bold'>Detail Data</h1>
        <Link
          to='/'
          className='bg-red-500 text-white px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded inline'>
          Kembali
        </Link>
      </div>
      <div className='px-4 flex flex-row justify-end items-center md:mt-4 mt-2 '>
        <CetakDatilHitung uuid={uuid} nama={namaMitra} tableMode={false} />
      </div>
      <div className='p-5 my-3 w-full'>
        <TabelDetail uuid={uuid} />
      </div>
      <div className='p-4'>
        <h1 className='md:text-4xl text-2xl font-bold'>Rekap Data</h1>
      </div>
      <div className='p-5 my-3 w-full'>
        <TabelRekapDetail uuid={uuid} />
      </div>
    </div>
  );
}

export default DetailHitungBerat;
