import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  // const isLoggedIn = localStorage.getItem("isLoggedIn");
  // console.log(isLoggedIn);
  const storedUser = sessionStorage.getItem("user");
  // console.log(storedUser);

  if (!storedUser) {
    return <Navigate to='/login' />;
  }
  return children;
};

export default ProtectedRoute;
