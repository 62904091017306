import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import CetakDetailPengiriman from "../print/CetakDetailPengiriman";

const months = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const years = Array.from(new Array(30), (val, index) => 2000 + index);

export default function TabelPengirimanBarang({ data, onDetail }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const onEdit = (row) => {
    navigate(`/edit-pengiriman-barang/${row.id}`);
  };

  const filteredData = data.filter((row) => {
    const rowDate = new Date(row.tanggal_terbit);
    const rowMonth = rowDate.getMonth(); // 0-based month
    const rowYear = rowDate.getFullYear();

    const isMonthMatch = selectedMonth
      ? rowMonth === months.indexOf(selectedMonth)
      : true;

    const isYearMatch = selectedYear ? rowYear === Number(selectedYear) : true;

    const isSearchMatch =
      row.tujuan?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.no_seri?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.jenis_hhbk?.toLowerCase().includes(searchQuery.toLowerCase());

    return isSearchMatch && isMonthMatch && isYearMatch;
  });

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const visibleRows = useMemo(
    () =>
      filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, filteredData]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <div className='flex flex-end sm:flex-row flex-wrap sm:gap-3 gap-5 md:text-base text-xs mb-5'>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            sx={{ width: 120, height: 35 }}
            size='small'>
            <MenuItem value=''>
              <p>Bulan</p>
            </MenuItem>
            {months.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={selectedYear}
            onChange={handleYearChange}
            sx={{ width: 100, height: 35 }}
            size='small'>
            <MenuItem value=''>
              <p>Tahun</p>
            </MenuItem>
            {years.map((year, index) => (
              <MenuItem key={index} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
          <div>
            <CetakDetailPengiriman
              data={filteredData}
              month={selectedMonth}
              year={selectedYear}
            />
          </div>
          <TextField
            label='Cari No Seri, Jenis HHBK, Tujuan'
            variant='outlined'
            size='small'
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              sx: {
                height: 36,
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: 36,
              },
            }}
          />
        </div>
      </Box>

      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size='medium'>
            <EnhancedTableHead />
            <TableBody>
              {visibleRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <div className='md:text-sm text-xs'>{index + 1}</div>
                  </TableCell>
                  <TableCell>
                    <div className='md:text-sm text-xs'>{row.no_seri}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.jenis_hhbk}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.tujuan}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.volume}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.satuan}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='flex flex-row justify-center md:text-sm text-xs'>
                      <Button
                        variant='contained'
                        size='small'
                        onClick={() => onDetail(row.id)}>
                        Detail
                      </Button>
                      <Button
                        variant='outlined'
                        size='small'
                        onClick={() => onEdit(row)}
                        sx={{ mx: 1 }}>
                        Edit
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component='div'
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-toolbar": {
              fontSize: "0.75rem",
            },
            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
              {
                fontSize: "0.75rem",
              },
            ".MuiTablePagination-select": {
              fontSize: "0.75rem",
            },
          }}
        />
      </Paper>
    </Box>
  );
}

function EnhancedTableHead() {
  const headCells = [
    { id: "no", label: "No", numeric: false, align: "left" },
    { id: "no_seri", label: "No Seri", numeric: false, align: "left" },
    {
      id: "jenis_hhbk",
      label: "Jenis HHBK",
      numeric: false,
      align: "center",
    },
    {
      id: "tujuan",
      label: "Tujuan",
      numeric: false,
      align: "center",
    },
    {
      id: "volume",
      label: "Volume",
      numeric: true,
      align: "center",
    },
    {
      id: "satuan",
      label: "Satuan",
      numeric: true,
      align: "center",
    },
    { id: "aksi", label: "Aksi", numeric: false, align: "center" },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align}>
            <div className='md:text-base text-xs font-semibold'>
              {headCell.label}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
