import React from "react";
import { FiFileText, FiSettings, FiUser } from "react-icons/fi";
import { BsFillCalculatorFill } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";
import { Link } from "react-router-dom";

function Bottombar() {
  return (
    <div className='fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-2 2xl:hidden flex flex-row justify-around z-10'>
      <Link to='/' className='flex flex-col items-center w-1/5 text-center'>
        <BsFillCalculatorFill />
        <span className='text-xs'>Hitung Berat</span>
      </Link>
      <Link
        to='/pengiriman-barang'
        className='flex flex-col items-center w-1/5 text-center'>
        <TbTruckDelivery />
        <span className='text-xs'>Pengiriman</span>
      </Link>
      <Link
        to='/laporan'
        className='flex flex-col items-center w-1/5 text-center'>
        <FiFileText />
        <span className='text-xs'>Laporan</span>
      </Link>
      <Link
        to='/pengaturan'
        className='flex flex-col items-center w-1/5 text-center'>
        <FiSettings />
        <span className='text-xs'>Pengaturan</span>
      </Link>
      <Link to='/akun' className='flex flex-col items-center w-1/5 text-center'>
        <FiUser />
        <span className='text-xs'>Akun</span>
      </Link>
    </div>
  );
}

export default Bottombar;
