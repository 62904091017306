import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Swal from "sweetalert2";

import TabelRekap from "../../components/table/TabelRekap";

function FormEditHitungBerat() {
  const [nama, setNama] = useState("");
  const [noSegel, setNoSegel] = useState("");
  const [beratTotalKotor, setBeratTotalKotor] = useState("");
  const [beratGetahKotor, setBeratGetahKotor] = useState("");
  const [karung, setKarung] = useState("");
  const [beratBersih, setBeratBersih] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const { status, uuid, nama_mitra, no_segel } = useParams();

  const [penyusutan, setPenyusutan] = useState(0);
  const [beratWadah, setBeratWadah] = useState(0);

  const [errorTotalKotor, setErrorTotalKotor] = useState(false);
  const [errorGetahKotor, setErrorGetahKotor] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const key = location.state?.reload || "";

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/rekap_mitra_detail/${uuid}/${nama_mitra}/${no_segel}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.length > 0) {
          const item = data[0];
          setNama(item.nama_mitra);
          setNoSegel(item.no_segel);
          setBeratTotalKotor(item.berat_total_kotor);
          setBeratGetahKotor(item.berat_getah_kotor);
          setKarung(item.karung);
          setBeratBersih(item.berat_bersih);
          setUpdatedAt(new Date().toISOString().slice(0, 10)); // Gunakan updated_at
        }
      })
      .catch((err) => console.log(err));
  }, [uuid, nama_mitra, no_segel]);

  const handleBeratTotalKotorChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setBeratTotalKotor(value);
      setErrorTotalKotor(false);
      setErrorGetahKotor(false);
      calculateBeratBersih(value);
      calculateGetahKotorChange(value);
    } else {
      setErrorTotalKotor(true);
      setErrorGetahKotor(true);
    }
  };

  const calculateGetahKotorChange = (totalKotor) => {
    if (totalKotor) {
      const bersih = totalKotor - parseFloat(beratWadah);
      setBeratGetahKotor(bersih.toFixed(2));
    } else {
      setBeratGetahKotor("");
    }
  };

  const calculateBeratBersih = (totalKotor) => {
    if (totalKotor) {
      const bersih =
        totalKotor -
        parseFloat(beratWadah) -
        (totalKotor - parseFloat(beratWadah)) * parseFloat(penyusutan);
      setBeratBersih(bersih.toFixed(2));
    } else {
      setBeratBersih("");
    }
  };

  useEffect(() => {
    calculateBeratBersih(beratTotalKotor, beratGetahKotor, karung);
  }, [beratTotalKotor, beratGetahKotor, karung]);

  const handleSubmitForm = () => {
    const formData = {
      nama_mitra_baru: nama,
      no_segel: noSegel,
      berat_total_kotor: beratTotalKotor,
      berat_getah_kotor: beratGetahKotor,
      karung,
      berat_bersih: beratBersih,
      updated_at: new Date().toISOString().slice(0, 10),
    };

    // Validasi: Pastikan semua data form terisi
    const isFormComplete = Object.values(formData).every(
      (value) => value !== undefined && value !== ""
    );

    if (!isFormComplete) {
      Swal.fire({
        title: "Peringatan!",
        text: "Harap isi semua kolom yang diperlukan sebelum menyimpan.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validasi error pada berat total kotor dan berat getah kotor
    if (errorTotalKotor || errorGetahKotor) {
      Swal.fire({
        title: "Peringatan",
        text: "Input berat total kotor atau berat getah kotor dengan angka yang benar.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Fetch untuk update rekap mitra detail
    fetch(
      `${process.env.REACT_APP_API_URL}/rekap_mitra_detail/${uuid}/${nama_mitra}/${no_segel}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (status === "Personal") {
          fetch(`${process.env.REACT_APP_API_URL}/rekap_mitra/${uuid}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ nama_mitra: nama }),
          })
            .then((res) => res.json())
            .then(() => {
              Swal.fire({
                title: "Berhasil!",
                text: "Data berhasil di update.",
                icon: "success",
                confirmButtonText: "OK",
              }).then(() => {
                navigate(`/edit-hitung-berat/${status}/${uuid}/${nama_mitra}`);
                window.location.reload();
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Gagal!",
                text: `Terjadi kesalahan saat menyimpan data. \nError: ${err}`,
                icon: "error",
                confirmButtonText: "OK",
              });
            });
        } else {
          Swal.fire({
            title: "Berhasil!",
            text: "Data berhasil di update.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Gagal!",
          text: `Terjadi kesalahan saat menyimpan data. \nError: ${err}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className='w-full' key={key}>
      <div className='p-4 flex flex-row justify-between'>
        <h1 className='md:text-4xl text-2xl font-bold'>Hitung Berat</h1>
        <Link
          to='/'
          className='bg-red-500 text-white px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded inline'>
          Kembali
        </Link>
      </div>
      <div className='px-5 md:py-4 py-2 md:text-base text-xs'>
        <div className='flex flex-row md:gap-4 gap-2'>
          <div className='w-1/2'>
            <label>UUID</label>
            <input
              type='text'
              name='uuid'
              value={uuid}
              disabled
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
          <div className='w-1/2'>
            <label>Tanggal</label>
            <input
              type='text'
              name='tanggal'
              value={updatedAt}
              disabled
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
        </div>
        <div className='flex flex-row md:gap-4 gap-2'>
          <div className='w-1/2'>
            <label>Nama</label>
            <input
              type='text'
              name='nama'
              value={nama}
              onChange={(e) => setNama(e.target.value)}
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
          <div className='w-1/2'>
            <label>Nomor Segel</label>
            <input
              type='text'
              name='nomorSegel'
              value={noSegel}
              onChange={(e) => setNoSegel(e.target.value)}
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
        </div>
        <div className='flex flex-row md:gap-4 gap-2'>
          <div className='w-1/2'>
            <label>Berat Total Kotor</label>
            <input
              type='text'
              name='beratTotalKotor'
              value={beratTotalKotor}
              onChange={handleBeratTotalKotorChange}
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
          <div className='w-1/2'>
            <label>Berat Getah Kotor</label>
            <input
              type='text'
              name='beratGetahKotor'
              value={beratGetahKotor}
              disabled
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
        </div>
        <div className='flex flex-row md:gap-4 gap-2'>
          <div className='w-1/2'>
            <label>Berat Bersih</label>
            <input
              type='text'
              name='beratBersih'
              value={beratBersih}
              disabled
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
        </div>
        <div className='flex justify-end'>
          <button
            className='bg-green-500 text-white px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded'
            onClick={handleSubmitForm}>
            Update
          </button>
        </div>
      </div>
      <div className='p-5 my-3 w-full'>
        <TabelRekap uuid={uuid} />
      </div>
    </div>
  );
}

export default FormEditHitungBerat;
