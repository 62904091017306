import React from "react";
import Swal from "sweetalert2";
import * as XLSX from "xlsx"; // Import pustaka xlsx

function CetakDetailLaporan({ data, month, year }) {
  const handleKlik = async () => {
    const storedUser = sessionStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : null;

    if (user && user.role === "admin") {
      try {
        // Buat array data yang akan diekspor ke Excel
        const exportData = data.map((row, index) => ({
          NO: index + 1,
          "No Seri SKSHHBK": row.no_seri,
          "Tanggal Terbit": new Date(row.tanggal_terbit).toLocaleDateString(),
          "Tanggal LHP": "", // Kolom kosong untuk Tanggal LHP
          "Jenis HHBK": row.jenis_hhbk,
          "Ket Lunas": "", // Kolom kosong untuk Ket Lunas
          TUJUAN: row.tujuan,
          "VOLUME (Kg)": row.volume,
          "SATUAN (Ton)": row.satuan,
        }));

        // Tambahkan total volume dan satuan ke dalam data
        const totalVolume = data.reduce((sum, row) => sum + row.volume, 0);
        const totalSatuan = data.reduce((sum, row) => sum + row.satuan, 0);

        exportData.push({
          NO: "",
          "No Seri SKSHHBK": "Grand Total",
          "Tanggal Terbit": "",
          "Tanggal LHP": "",
          "Jenis HHBK": "",
          "Ket Lunas": "",
          TUJUAN:
            month && year
              ? `Bulan ${month} ${year}`
              : year
              ? `Tahun ${year}`
              : "",
          "VOLUME (Kg)": totalVolume.toFixed(2),
          "SATUAN (Ton)": totalSatuan.toFixed(2),
        });

        // Buat workSheet dan workBook untuk file Excel
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        // Tentukan lebar kolom
        worksheet["!cols"] = [
          { wpx: 50 },
          { wpx: 100 },
          { wpx: 100 },
          { wpx: 100 },
          { wpx: 100 },
          { wpx: 100 },
          { wpx: 100 },
          { wpx: 100 },
          { wpx: 100 },
        ];

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Laporan HHBK");

        // Simpan file Excel
        XLSX.writeFile(
          workbook,
          `Laporan_HHBK_${month || ""}_${year || ""}.xlsx`
        );
      } catch (error) {
        console.error("Error generating Excel file:", error);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Akses Ditolak",
        text: "Hanya admin yang boleh mencetak",
      });
    }
  };

  return (
    <button
      onClick={handleKlik}
      className='bg-teal-600 text-white px-4 py-[5px] rounded sm:text-lg text-sm border-neutral-500 border-2'>
      Cetak
    </button>
  );
}

export default CetakDetailLaporan;
