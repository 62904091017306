import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function EnhancedTableHead() {
  const headCells = [
    { id: "no", label: "No", numeric: false, align: "left" },
    {
      id: "nama",
      label: "Nama Penderes/Mitra",
      numeric: false,
      align: "center",
    },
    { id: "nomorSegel", label: "No. Segel", numeric: false, align: "center" },
    {
      id: "beratTotalKotor",
      label: "Berat Total Kotor",
      numeric: false,
      align: "center",
    },
    { id: "beratWadah", label: "Berat Wadah", numeric: false, align: "center" },
    {
      id: "beratGetahKotor",
      label: "Berat Getah Kotor",
      numeric: false,
      align: "center",
    },
    { id: "penyusutan", label: "Penyusutan", numeric: false, align: "center" },
    {
      id: "beratBersih",
      label: "Berat Bersih",
      numeric: false,
      align: "center",
    },
    { id: "karung", label: "Karung", numeric: false, align: "center" },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align}>
            <div className='md:text-base text-xs font-semibold'>
              {headCell.label}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TabelDetail({ uuid }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/rekap_mitra_detail/${uuid}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [uuid]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = useMemo(
    () => data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, data]
  );

  return (
    <Box id='table-to-print' sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", overflowX: "auto", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{
              width: {
                xs: "200%",
                sm: "150%",
                md: "100%",
              },
            }}
            size='medium'>
            <EnhancedTableHead />
            <TableBody>
              {visibleRows.map((row, index) => (
                <TableRow key={row.uuid}>
                  <TableCell>
                    <div className='md:text-sm text-xs'>{index + 1}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.nama_mitra}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.no_segel}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>
                      {row.berat_total_kotor}
                    </div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.berat_wadah}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>
                      {row.berat_getah_kotor}
                    </div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.penyusutan}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.berat_bersih}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.karung}</div>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-toolbar": {
              fontSize: "0.75rem",
            },
            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
              {
                fontSize: "0.75rem",
              },
            ".MuiTablePagination-select": {
              fontSize: "0.75rem",
            },
          }}
        />
      </Paper>
    </Box>
  );
}
