import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Swal from "sweetalert2";

import TabelRekap from "../../components/table/TabelRekap";
import axios from "axios";

function FormHitungBerat() {
  const [nama, setNama] = useState("");
  const [uuid, setUuid] = useState("");

  const [noSegel, setNoSegel] = useState("");
  const [beratTotalKotor, setBeratTotalKotor] = useState(0);
  const karung = 1;
  const [beratBersih, setBeratBersih] = useState(0);
  const [beratGetahKotor, setBeratGetahKotor] = useState(0);

  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const { nama_mitra } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mitraFound, setMitraFound] = useState(false);

  const [penyusutan, setPenyusutan] = useState(0);
  const [beratWadah, setBeratWadah] = useState(0);

  const [errorTotalKotor, setErrorTotalKotor] = useState(false);
  const [errorGetahKotor, setErrorGetahKotor] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const key = location.state?.reload || "";

  useEffect(() => {
    if (nama_mitra) {
      fetch(
        `${process.env.REACT_APP_API_URL}/rekap_mitra/${encodeURIComponent(
          nama_mitra
        )}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.uuid && data.status === "Mitra") {
            setUuid(data.uuid);
            setMitraFound(true);
          } else {
            setUuid(uuidv4());
          }
        })
        .catch((err) => console.log(err));
    }

    const currentDate = new Date().toISOString().slice(0, 10);
    setCreatedAt(currentDate);
    setUpdatedAt(currentDate);

    fetch(`${process.env.REACT_APP_API_URL}/pengaturan`)
      .then((res) => res.json())
      .then((data) => {
        setPenyusutan(data.penyusutan);
        setBeratWadah(data.berat_wadah);
      })
      .catch((err) => console.log(err));
  }, [nama_mitra]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/pengaturan`)
      .then((response) => {
        setNoSegel(response.data.kode_unik.toString().padStart(6, "0"));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleNamaChange = (e) => {
    setNama(e.target.value);
  };

  const handleNoSegelChange = (e) => {
    setNoSegel(e.target.value);
  };

  const handleBeratTotalKotorChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setBeratTotalKotor(value);
      setErrorTotalKotor(false);
      setErrorGetahKotor(false);
      calculateBeratBersih(value);
      calculateBeratGetahKotor(value);
    } else {
      setErrorTotalKotor(true);
      setErrorGetahKotor(true);
    }
  };

  const calculateBeratGetahKotor = (totalKotor) => {
    if (totalKotor) {
      const bersih = totalKotor - parseFloat(beratWadah);
      setBeratGetahKotor(bersih.toFixed(2));
    } else {
      setBeratGetahKotor("");
    }
  };

  const calculateBeratBersih = (totalKotor) => {
    if (totalKotor) {
      const bersih =
        totalKotor -
        parseFloat(beratWadah) -
        (totalKotor - parseFloat(beratWadah)) * parseFloat(penyusutan);
      setBeratBersih(bersih.toFixed(2));
    } else {
      setBeratBersih("");
    }
  };

  const handleSubmitForm = () => {
    if (!nama || !noSegel || !beratTotalKotor || !beratGetahKotor) {
      Swal.fire({
        title: "Peringatan!",
        text: "Harap isi semua kolom yang diperlukan sebelum menyimpan.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (errorTotalKotor || errorGetahKotor) {
      Swal.fire({
        title: "Peringatan",
        text: "Input berat total kotor atau berat getah kotor dengan angka yang benar.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Directly set the status based on the `nama_mitra`
    const currentStatus = nama_mitra === "personal" ? "personal" : "mitra";

    const rekapMitraData = {
      uuid,
      nama_mitra: nama_mitra === "personal" ? nama : nama_mitra,
      status: currentStatus,
      created_at: new Date(),
      updated_at: new Date(),
    };

    setIsSubmitting(true); // Nonaktifkan tombol submit saat pengiriman dimulai

    if (!mitraFound) {
      fetch(`${process.env.REACT_APP_API_URL}/rekap_mitra`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(rekapMitraData),
      })
        .then((res) => res.json())
        .then((data) => {
          setUuid(data.uuid);
          saveRekapMitraDetail(uuid);
        })
        .catch((err) => {
          setIsSubmitting(false);
        });
    } else {
      saveRekapMitraDetail(uuid);
    }
  };

  // save data
  const saveRekapMitraDetail = (uuid) => {
    const newNoSegel = parseInt(noSegel) + 1;

    const formData = {
      uuid,
      nama_mitra: nama,
      no_segel: noSegel,
      berat_total_kotor: beratTotalKotor,
      berat_wadah: beratWadah,
      berat_getah_kotor: beratGetahKotor,
      penyusutan: penyusutan,
      berat_bersih: beratBersih,
      karung,
      created_at: createdAt,
      updated_at: updatedAt,
    };
    fetch(`${process.env.REACT_APP_API_URL}/rekap_mitra_detail`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then(() => {
        // Setelah menyimpan data, update kode_unik di backend
        axios.put(`${process.env.REACT_APP_API_URL}/pengaturan/kode_unik`, {
          kode_unik: newNoSegel, // Update kode_unik dengan nilai baru
        });
        Swal.fire({
          title: "Berhasil!",
          text: "Data berhasil disimpan.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          setIsSubmitting(false);
          navigate(`/form-hitung-berat/${nama_mitra}`);
          window.location.reload();
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Gagal!",
          text: `Terjadi kesalahan saat menyimpan data. \nError: ${err}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        setIsSubmitting(false);
      });
  };

  return (
    <div className='w-full' key={key}>
      <div className='p-4 flex flex-row justify-between'>
        <h1 className='md:text-4xl text-2xl font-bold'>Hitung Berat</h1>
        <Link
          to='/'
          className='bg-red-500 text-white px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded inline'>
          Kembali
        </Link>
      </div>
      <div className='px-5 md:py-4 py-2 md:text-base text-xs'>
        <div className='flex flex-row md:gap-4 gap-2'>
          <div className='w-1/2'>
            <label>UUID</label>
            <input
              type='text'
              name='uuid'
              value={uuid}
              disabled
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
          <div className='w-1/2'>
            <label>Tanggal</label>
            <input
              type='text'
              name='tanggal'
              value={createdAt}
              disabled
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
        </div>
        <div className='flex flex-row md:gap-4 gap-2'>
          <div className='w-1/2'>
            <label>Nama</label>
            <input
              type='text'
              name='nama'
              value={nama}
              onChange={handleNamaChange}
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
          <div className='w-1/2'>
            <label>Nomor Segel</label>
            <input
              type='text'
              name='nomorSegel'
              value={noSegel}
              onChange={handleNoSegelChange}
              disabled
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
        </div>
        <div className='flex flex-row md:gap-4 gap-2'>
          <div className='w-1/2'>
            <label>Berat Total Kotor</label>
            <input
              type='number'
              name='beratTotalKotor'
              value={beratTotalKotor}
              onChange={handleBeratTotalKotorChange}
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
          <div className='w-1/2'>
            <label>Berat Getah Kotor</label>
            <input
              type='text'
              name='beratGetahKotor'
              value={beratGetahKotor}
              disabled
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
        </div>
        <div className='flex flex-row md:gap-4 gap-2'>
          <div className='w-1/2'>
            <label>Berat Bersih</label>
            <input
              type='text'
              name='beratBersih'
              value={beratBersih}
              disabled
              className='w-full border border-gray-300 p-2 mb-2'
            />
          </div>
        </div>
        <div className='flex justify-end'>
          <button
            className='bg-green-500 text-white px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded'
            onClick={handleSubmitForm}
            disabled={isSubmitting}>
            {isSubmitting ? "Menyimpan..." : "Simpan"}
          </button>
        </div>
      </div>
      <div className='p-5 my-3 w-full'>
        {mitraFound ? (
          <TabelRekap uuid={uuid} />
        ) : (
          <div className='flex justify-center text-xl font-bold'>Data baru</div>
        )}
      </div>
    </div>
  );
}

export default FormHitungBerat;
