import React, { useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

const ModalHitungBerat = ({ modalIsOpen, closeModal }) => {
  const [selectedType, setSelectedType] = useState("");
  const [mitraName, setMitraName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Hook untuk navigasi

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedType(value);
    setError(""); // Reset error saat pilihan berubah
  };

  const handleSave = () => {
    if (!selectedType) {
      setError("Pilih kategori terlebih dahulu.");
      return;
    }

    if (selectedType === "mitra" && !mitraName.trim()) {
      setError("Masukkan nama mitra.");
      return;
    }

    const route =
      selectedType === "personal"
        ? "/form-hitung-berat/personal"
        : `/form-hitung-berat/${encodeURIComponent(mitraName)}`;

    navigate(route); // Navigasi ke route yang sesuai
    closeModal(); // Menutup modal setelah navigasi
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel='Mitra atau Personal'
      className='flex items-center pb-3 px-8 mt-40 mx-auto shadow-lg justify-center w-[350px] h-[300px] bg-neutral-200 relative'>
      <div className='rounded w-full'>
        <h1 className='text-center md:text-2xl text-xl font-bold mb-4'>
          Perhatian!
        </h1>
        <h2 className='md:text-lg text-base font-bold mb-4'>Pilih Kategori</h2>
        {error && <p className='text-red-500 mb-4'>{error}</p>}{" "}
        {/* Menampilkan pesan error */}
        <div className='mb-4'>
          <label className='block mb-2'>
            <input
              type='radio'
              value='mitra'
              checked={selectedType === "mitra"}
              onChange={handleRadioChange}
            />{" "}
            Mitra
          </label>
          {selectedType === "mitra" && (
            <input
              type='text'
              className='w-full md:p-2 p-1.5 border border-gray-300 rounded mb-4'
              placeholder='Masukkan nama mitra'
              value={mitraName}
              onChange={(e) => setMitraName(e.target.value)}
            />
          )}
        </div>
        <div className='mb-4'>
          <label className='block mb-2'>
            <input
              type='radio'
              value='personal'
              checked={selectedType === "personal"}
              onChange={handleRadioChange}
            />{" "}
            Personal
          </label>
        </div>
        <button
          onClick={handleSave}
          className='bg-blue-500 text-white px-4 py-2 rounded absolute right-4 bottom-4'>
          Simpan
        </button>
      </div>
    </Modal>
  );
};

export default ModalHitungBerat;
