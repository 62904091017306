import React, { useEffect, useState } from "react";
import TabelLaporan from "../../components/table/TabelLaporan";

function Laporan() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/kirim_barang`)
      .then((res) => res.json())
      .then((barang) => setData(barang))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className='w-full'>
      <div className='p-4 flex flex-row justify-between items-center'>
        <h1 className='md:text-4xl text-2xl font-bold'>Laporan</h1>
      </div>
      <div>
        <div>
          <div className='flex flex-row justify-between px-5 py-2 items-center'>
            <div className='md:text-2xl text-bas font-semibold'>List Data</div>
          </div>
          <div className='my-3 px-5'>
            <TabelLaporan data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Laporan;
