import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function FormBuatAkun() {
  const navigate = useNavigate();

  const [phoneError, setPhoneError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    nama_user: "",
    hp: "",
    username: "",
    password: "",
    role: "admin",
    fotoProfil: null,
  });

  const handleFormChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      if (name === "hp" && !/^\d*$/.test(value)) {
        setPhoneError("Nomor HP hanya boleh berisi angka.");
      } else {
        setPhoneError("");
      }
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    if (
      !formData.nama_user ||
      !formData.hp ||
      !formData.username ||
      !formData.password
    ) {
      Swal.fire({
        title: "Peringatan!",
        text: "Harap isi semua data dengan benar sebelum membuat akun.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    const formPayload = new FormData();
    formPayload.append("nama_user", formData.nama_user);
    formPayload.append("hp", formData.hp);
    formPayload.append("username", formData.username);
    formPayload.append("password", formData.password);
    formPayload.append("role", formData.role);
    formPayload.append("fotoProfil", formData.fotoProfil);
    console.log("Form Data:", ...formPayload.entries());

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: "POST",
        body: formPayload,
      });

      const data = await res.json();
      if (res.ok) {
        Swal.fire({
          title: "Berhasil!",
          text: "Data berhasil disimpan.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/akun");
        });
      } else {
        Swal.fire({
          title: "Gagal!",
          text: data.message || "Terjadi kesalahan saat menyimpan data.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Gagal!",
        text: `Terjadi kesalahan saat menyimpan data. Error: ${err}`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className='w-full'>
      <div className='flex flex-row justify-between px-4 py-5 items-center'>
        <h1 className='md:text-4xl text-2xl font-bold'>Akun</h1>
        <Link
          to='/akun'
          className='rounded-lg md:px-4 px-2 md:py-2 py-1.5 md:text-base text-sm bg-red-500 text-neutral-50 font-semibold'>
          Kembali
        </Link>
      </div>

      <div className='px-5 md:py-4 py-2 md:text-base text-xs'>
        <form onSubmit={handleSubmitForm}>
          <div className='flex flex-row md:gap-4 gap-2'>
            <div className='w-1/2'>
              <label>Nama User</label>
              <input
                type='text'
                name='nama_user'
                value={formData.nama_user}
                onChange={handleFormChange}
                className='w-full border border-gray-300 p-2 mb-2'
              />
            </div>
            <div className='w-1/2'>
              <label>No. HP</label>
              <input
                type='text'
                name='hp'
                value={formData.hp}
                onChange={handleFormChange}
                className='w-full border border-gray-300 p-2 mb-2'
              />
              {phoneError && <p className='text-red-500'>{phoneError}</p>}
            </div>
          </div>
          <div className='flex flex-row md:gap-4 gap-2'>
            <div className='w-1/2'>
              <label>Username</label>
              <input
                type='text'
                name='username'
                value={formData.username}
                onChange={handleFormChange}
                className='w-full border border-gray-300 p-2 mb-2'
              />
            </div>
            <div className='w-1/2'>
              <label>Password</label>
              <div className='relative'>
                <input
                  type={showPassword ? "text" : "password"}
                  name='password'
                  value={formData.password}
                  onChange={handleFormChange}
                  className='w-full border border-gray-300 p-2 mb-2'
                />
                <button
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                  className='absolute right-2 top-2 text-gray-500'>
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>
          </div>
          <div className='flex flex-row md:gap-4 gap-2'>
            <div className='w-1/2'>
              <label>Role</label>
              <select
                name='role'
                value={formData.role}
                onChange={handleFormChange}
                className='w-full border border-gray-300 p-2 mb-2'>
                <option value='admin'>Admin</option>
                <option value='editor'>Editor</option>
              </select>
            </div>
            <div className='w-1/2'>
              <label>Foto Profil</label>
              <input
                type='file'
                name='fotoProfil'
                accept='image/*'
                onChange={handleFormChange}
                className='w-full border border-gray-300 p-1.5 mb-2'
              />
            </div>
          </div>
          <div className='flex justify-end'>
            <button
              type='submit'
              className='bg-green-500 text-white px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded'>
              Simpan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormBuatAkun;
