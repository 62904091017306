import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function EnhancedTableHead() {
  const headCells = [
    { id: "no", label: "No", numeric: false, align: "left" },
    { id: "nama", label: "Nama", numeric: false, align: "center" },
    { id: "karung", label: "Total Karung", numeric: true, align: "center" },
    {
      id: "beratBersih",
      label: "Total Berat Bersih",
      numeric: false,
      align: "center",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align}>
            <div className='md:text-base text-xs font-semibold'>
              {headCell.label}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TabelRekapDetail({ uuid }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/sum_rekap_mitra_detail?uuid=${uuid}`
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [uuid]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = useMemo(
    () => data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, data]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", overflowX: "auto", mb: 2 }}>
        <TableContainer>
          <Table size='medium'>
            <EnhancedTableHead />
            <TableBody>
              {visibleRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <div className='md:text-sm text-xs'>{index + 1}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.nama_mitra}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.totalKarung}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>
                      {row.totalBeratBersih.toFixed(2)} kg
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-toolbar": {
              fontSize: "0.75rem",
            },
            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
              {
                fontSize: "0.75rem",
              },
            ".MuiTablePagination-select": {
              fontSize: "0.75rem",
            },
          }}
        />
      </Paper>
    </Box>
  );
}
