import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { FiFileText, FiSettings, FiUser } from "react-icons/fi";
import { BsFillCalculatorFill } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";

function Sidebar({ isSidebarOpen, toggleSidebar }) {
  const navigate = useNavigate();

  const menuItems = [
    { icon: <BsFillCalculatorFill />, label: "Hitung Berat", target: "/" },
    {
      icon: <TbTruckDelivery />,
      label: "Pengiriman Barang",
      target: "/pengiriman-barang",
    },
    { icon: <FiFileText />, label: "Laporan", target: "/laporan" },
    { icon: <FiSettings />, label: "Pengaturan", target: "/pengaturan" },
    { icon: <FiUser />, label: "Akun", target: "/akun" },
  ];

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    localStorage.removeItem("isLoggedIn");
    navigate("/login");
  };

  return (
    <div
      className={`bg-gray-800 text-white h-full transition-all duration-300 z-10 ${
        isSidebarOpen ? "md:w-56 w-52" : "hidden"
      } fixed`}>
      <div className='gap-2 flex flex-col mt-8'>
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.target}
            // onClick={toggleSidebar}
            className={`flex items-center px-4 md:py-4 py-2 hover:bg-gray-700 cursor-pointer  ${
              isSidebarOpen ? "" : " justify-center"
            }`}>
            <div className='text-xl'>{item.icon}</div>
            {isSidebarOpen && <span className='ml-4'>{item.label}</span>}
          </Link>
        ))}
      </div>

      <div className='flex items-center p-4 cursor-pointer justify-center mt-8'>
        {isSidebarOpen && (
          <button
            onClick={handleLogout}
            className='border md:py-2 py-1 md:px-8 px-6 rounded-xl hover:bg-gray-700'>
            Keluar
          </button>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
