import React, { useEffect, useState } from "react";
import TabelPengirimanBarang from "../../components/table/TabelPengiriman";
import { Link } from "react-router-dom";

function PengirimanBarang() {
  const [formVisible, setFormVisible] = useState(false);
  const [isDetailMode, setIsDetailMode] = useState(false);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/kirim_barang`)
      .then((res) => res.json())
      .then((barang) => setData(barang))
      .catch((err) => console.log(err));
  }, []);

  const handleDetail = (id) => {
    const itemToDetail = data.find((item) => item.id === id);
    setFormData(itemToDetail);
    setIsDetailMode(true);
    setFormVisible(true);
  };

  const handleHideForm = () => {
    setFormVisible(false);
    setIsDetailMode(false);
    setFormData({});
  };

  return (
    <div className='w-full'>
      <div className='p-4 flex flex-row justify-between items-center'>
        <h1 className='md:text-4xl text-2xl font-bold'>Pengiriman Barang</h1>
        {formVisible && (
          <button
            className='bg-red-500 text-white px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded inline'
            onClick={handleHideForm}>
            Kembali
          </button>
        )}
      </div>
      <div>
        {!formVisible && (
          <div>
            <div className='flex flex-row justify-between px-5 py-2 items-center'>
              <div className='md:text-2xl text-bas font-semibold'>
                List Data
              </div>
              <Link
                to='/form-pengiriman-barang'
                className='rounded-lg md:px-4 px-2 md:py-2 py-1.5 md:text-base text-sm bg-blue-500 text-white font-semibold'>
                Pengiriman Barang
              </Link>
            </div>
            <div className='my-3 px-5'>
              <TabelPengirimanBarang data={data} onDetail={handleDetail} />
            </div>
          </div>
        )}
        {formVisible && (
          <div className='flex flex-col p-5 gap-4 w-full'>
            <div className='flex flex-row items-center gap-4 w-full'>
              <div className='w-[180px]'>No. Seri</div>
              <input
                type='text'
                name='no_seri'
                value={formData.no_seri}
                className='border p-2 rounded w-full'
                readOnly={true}
              />
            </div>
            <div className='flex flex-row items-center gap-4 w-full'>
              <div className='w-[180px]'>Jenis HHKB</div>
              <input
                type='text'
                name='jenis_hhbk'
                value={formData.jenis_hhbk}
                className='border p-2 rounded w-full'
                readOnly={isDetailMode}
              />
            </div>
            <div className='flex flex-row items-center gap-4 w-full'>
              <div className='w-[180px]'>Tujuan</div>
              <input
                type='text'
                name='tujuan'
                value={formData.tujuan}
                className='border p-2 rounded w-full'
                readOnly={isDetailMode}
              />
            </div>
            <div className='flex flex-row items-center gap-4 w-full'>
              <div className='w-[180px]'>Volume</div>
              <input
                type='number'
                name='volume'
                value={formData.volume}
                className='border p-2 rounded w-full'
                readOnly={isDetailMode}
              />
            </div>
            <div className='flex flex-row items-center gap-4 w-full'>
              <div className='w-[180px]'>Satuan</div>
              <input
                type='number'
                name='satuan'
                value={formData.satuan}
                className='border p-2 rounded w-full'
                readOnly={isDetailMode}
              />
            </div>
            <div className='flex flex-row items-center gap-4 w-full'>
              <div className='w-[180px]'>Tanggal Terbit</div>
              <input
                type='date'
                name='tanggal_terbit'
                value={
                  formData.tanggal_terbit
                    ? formData.tanggal_terbit.slice(0, 10)
                    : ""
                }
                className='border p-2 rounded w-full'
                readOnly={isDetailMode}
              />
            </div>
            <div className='flex flex-row items-center gap-4 w-full'>
              <div className='w-[180px]'>Kondisi Barang</div>
              <select
                name='kondisi_barang'
                value={formData.kondisi_barang || "bagus"}
                className='border p-2 rounded w-full'
                disabled={isDetailMode}>
                <option value='bagus'>Bagus</option>
                <option value='rusak'>Rusak</option>
                <option value='bocor'>Bocor</option>
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PengirimanBarang;
