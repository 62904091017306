import React, { useState } from "react";
import Sidebar from "./components/global/Sidebar";
import Header from "./components/global/Header";
import Bottombar from "./components/global/Bottombar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import HitungBerat from "./pages/HitungBerat/HitungBerat";
import FormHitungBerat from "./pages/HitungBerat/FormHitungBerat";
import EditHitungBerat from "./pages/HitungBerat/EditHitungBerat";
import FormEditHitungBerat from "./pages/HitungBerat/FormEditHitungBerat";
import DetailHitungBerat from "./pages/HitungBerat/DetailHitungBerat";

import PengirimanBarang from "./pages/Pengiriman/PengirimanBarang";
import FormPengirimanBarang from "./pages/Pengiriman/FormPengirimanBarang";
import EditPengirimanBarang from "./pages/Pengiriman/EditPengirimanBarang";

import Laporan from "./pages/Laporan/Laporan";
import Pengaturan from "./pages/Pengaturan/Pengaturan";

import Akun from "./pages/Akun/Akun";
import FormBuatAkun from "./pages/Akun/FormBuatAkun";
import FormEditAkun from "./pages/Akun/EditAkun";

import Login from "./pages/Login";

import ProtectedRoute from "./ProtectedRoute";

function AppRouter() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className='w-full'>
      <Header toggleSidebar={toggleSidebar} />
      {!isLoginPage && (
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      )}
      <div>
        <main
          className={`my-16 ${
            isSidebarOpen && location.pathname !== "/login"
              ? "md:ml-60 sm:ml-52"
              : ""
          }`}>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route
              path='/'
              element={
                <ProtectedRoute>
                  <HitungBerat />
                </ProtectedRoute>
              }
            />
            <Route
              path='/form-hitung-berat/:nama_mitra'
              element={
                <ProtectedRoute>
                  <FormHitungBerat />
                </ProtectedRoute>
              }
            />
            <Route
              path='/edit-hitung-berat/:status/:uuid'
              element={
                <ProtectedRoute>
                  <EditHitungBerat />
                </ProtectedRoute>
              }
            />
            <Route
              path='/edit-hitung-berat/:status/:uuid/:nama_mitra/:no_segel'
              element={
                <ProtectedRoute>
                  <FormEditHitungBerat />
                </ProtectedRoute>
              }
            />
            <Route
              path='/detail-hitung-berat/:uuid'
              element={
                <ProtectedRoute>
                  <DetailHitungBerat />
                </ProtectedRoute>
              }
            />
            <Route
              path='/pengiriman-barang'
              element={
                <ProtectedRoute>
                  <PengirimanBarang />
                </ProtectedRoute>
              }
            />
            <Route
              path='/form-pengiriman-barang'
              element={
                <ProtectedRoute>
                  <FormPengirimanBarang />
                </ProtectedRoute>
              }
            />
            <Route
              path='/edit-pengiriman-barang/:id'
              element={
                <ProtectedRoute>
                  <EditPengirimanBarang />
                </ProtectedRoute>
              }
            />
            <Route
              path='/detail-pengiriman-barang/:uuid'
              element={
                <ProtectedRoute>
                  <PengirimanBarang />
                </ProtectedRoute>
              }
            />
            <Route
              path='/laporan'
              element={
                <ProtectedRoute>
                  <Laporan />
                </ProtectedRoute>
              }
            />
            <Route
              path='/pengaturan'
              element={
                <ProtectedRoute>
                  <Pengaturan />
                </ProtectedRoute>
              }
            />
            <Route
              path='/akun'
              element={
                <ProtectedRoute>
                  <Akun />
                </ProtectedRoute>
              }
            />
            <Route
              path='/form-akun'
              element={
                <ProtectedRoute>
                  <FormBuatAkun />
                </ProtectedRoute>
              }
            />
            <Route
              path='/form-edit-akun/:username'
              element={
                <ProtectedRoute>
                  <FormEditAkun />
                </ProtectedRoute>
              }
            />
          </Routes>
        </main>
      </div>
      {!isLoginPage && <Bottombar />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppRouter />
    </Router>
  );
}

export default App;
