import React, { useEffect, useMemo, useState } from "react";

import axios from "axios";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";

function EnhancedTableHead() {
  const headCells = [
    { id: "no", label: "No", numeric: false, align: "left" },
    {
      id: "nama",
      label: "Nama Mitra / Personal",
      numeric: false,
      align: "center",
    },
    {
      id: "karung",
      label: "Jumlah Karung",
      numeric: true,
      align: "center",
    },
    {
      id: "beratBersih",
      label: "Berat Bersih",
      numeric: false,
      align: "center",
    },
    { id: "aksi", label: "Aksi", numeric: false, align: "center" },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align}>
            <div className='md:text-base text-xs font-semibold'>
              {headCell.label}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TabelRekap({ uuid }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  const { status } = useParams();

  useEffect(() => {
    if (!uuid) {
      return;
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/rekap_mitra_detail/${uuid}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [uuid]);

  // Pastikan useMemo tidak dipanggil secara kondisional
  const filteredData = useMemo(() => {
    return data?.filter((row) =>
      row.nama_mitra?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, data]);

  const visibleRows = useMemo(() => {
    return filteredData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [page, rowsPerPage, filteredData]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const onEdit = (row) => {
    navigate(
      `/edit-hitung-berat/${status}/${row.uuid}/${row.nama_mitra}/${row.no_segel}`
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <div className='flex flex-row justify-between items-center mb-8'>
        <h1 className='md:text-2xl text-lg font-bold'>Rekap Data</h1>
        <TextField
          label='Cari Nama Mitra'
          variant='outlined'
          size='small'
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            sx: {
              height: 36,
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: 36,
            },
          }}
        />
      </div>
      <Paper sx={{ width: "100%", overflowX: "auto", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{
              width: {
                xs: "200%",
                sm: "150%",
                md: "100%",
              },
            }}
            size='medium'>
            <EnhancedTableHead />
            <TableBody>
              {visibleRows.map((row, index) => (
                <TableRow key={row.uuid}>
                  <TableCell>
                    <div className='md:text-sm text-xs'>{index + 1}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.nama_mitra}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>{row.karung}</div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs'>
                      {row.berat_bersih.toFixed(2)} kg
                    </div>
                  </TableCell>
                  <TableCell align='center'>
                    <div className='md:text-sm text-xs flex flex-row justify-center'>
                      <Button
                        variant='outlined'
                        size='small'
                        onClick={() => onEdit(row)}>
                        Edit
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
