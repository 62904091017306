import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

function EditPengirimanBarang() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    no_seri: "",
    jenis_hhbk: "",
    tujuan: "",
    tanggal_terbit: "",
    volume: "",
    satuan: "",
    kondisi_barang: "bagus",
  });

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/kirim_barang/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setFormData({
          ...data,
          tanggal_terbit: formatDate(data.tanggal_terbit),
        });
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, [id]);

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitForm = async () => {
    const dataToSend = {
      ...formData,
      tanggal_terbit: new Date(formData.tanggal_terbit)
        .toISOString()
        .split("T")[0],
    };

    try {
      fetch(`${process.env.REACT_APP_API_URL}/kirim_barang/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
      })
        .then((res) => res.json())
        .then((data) => {
          Swal.fire({
            title: "Berhasil!",
            text: "Data berhasil disimpan.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/pengiriman-barang");
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Gagal!",
            text: `Terjadi kesalahan saat menyimpan data. \nError: ${err}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Gagal!",
        text: `Terjadi kesalahan saat menyimpan data. \nError: ${error}`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className='w-full'>
      <div className='p-4 flex flex-row justify-between items-center'>
        <h1 className='md:text-4xl text-2xl font-bold'>Pengiriman Barang</h1>
        <Link
          to='/pengiriman-barang'
          className='bg-red-500 text-white px-4 py-2 rounded'>
          Kembali
        </Link>
      </div>
      <div className='flex flex-col p-5 gap-4 w-full'>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Nomor Seri</div>
          <input
            type='text'
            name='no_seri'
            placeholder='Nomor Seri HHBK'
            value={formData.no_seri}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Jenis HHBK</div>
          <input
            type='text'
            name='jenis_hhbk'
            placeholder='Jenis HHBK'
            value={formData.jenis_hhbk}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Tujuan</div>
          <input
            type='text'
            name='tujuan'
            placeholder='Tujuan'
            value={formData.tujuan}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Volume</div>
          <input
            type='text'
            name='volume'
            placeholder='Volume'
            value={formData.volume}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Satuan</div>
          <input
            type='text'
            name='satuan'
            placeholder='Satuan'
            value={formData.satuan}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Tanggal Terbit</div>
          <input
            type='date'
            name='tanggal_terbit'
            placeholder='Tanggal Terbit'
            value={formData.tanggal_terbit}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Kondisi Barang</div>
          <select
            name='kondisi_barang'
            value={formData.kondisi_barang}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'>
            <option value='bagus'>Bagus</option>
            <option value='rusak'>Rusak</option>
            <option value='bocor'>Bocor</option>
          </select>
        </div>
        <div className='flex justify-end'>
          <button
            onClick={handleSubmitForm}
            className='bg-blue-500 text-white px-4 py-2 rounded'>
            Simpan
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditPengirimanBarang;
